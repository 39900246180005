import React from "react";
import CalltoAction from "../../layout/CallToAction";
import Footer from "../../layout/Footer";
import MainHeader from "../../layout/MainHeader";
import MapSection from "../../layout/MapSection";
import emailjs from 'emailjs-com';



export default function Contactanos(){

    function sendEmail(e:any){
        e.preventDefault();
        emailjs.sendForm('service_z658fs5', 'template_t5vwaga', e.target, 'user_Q9KfTFeZSFprVL9iUItaW').then(res => {
            alert('Mensaje Enviado con Exito');
            console.log(res);
        }).catch(err => {
            alert('Ocurrio un error al enviar');
            console.log(err);
        }
            
        );
    }

    return (
        <>
            <MainHeader />

        {/*<!--Page Title-->*/}
            <section className="page-title" style={{ 
        backgroundImage: `url(${process.env.PUBLIC_URL + 'images/background/bg-page-title2.jpg'})`,
        backgroundRepeat: 'no-repeat'
        }}>
            
                <div className="auto-container">
                    <div className="sec-title">
                        <h1>Contáctanos</h1>
                    </div>
                </div>
            </section>

            <br /><br />
            
            {/*{/*<!--Breadcrumb-->*/}
            <div className="breadcrumb-outer">
                <div className="auto-container">
                    <div className="bread-crumb text-center"><a href="/">Inicio</a> / <a href="#" className="current">Contáctanos</a></div>
                </div>
            </div>

            <br /><br />

            <MapSection />

            <br /><br />    

            {/*<!--Contact Section-->*/}
    <section className="contact-section no-padd-top">
    	<div className="auto-container">
        	<div className="row clearfix">
            	
                {/*<!--Form Column-->*/}
                <div className="column form-column col-lg-7 col-md-7 col-sm-6 col-xs-12">
                	<h2><strong>Envíanos un </strong> Mensaje</h2>
                    {/*<!--Default Form-->*/}
                    <div className="default-form">
                    	<form onSubmit={sendEmail} id="contact-form">
                        	
                            <div className="row clearfix">
                                <div className="col-md-6 col-sm-12 co-xs-12 form-group">
                                    <input type="text" name="username" placeholder="Nombre" />
                                </div>
                                <div className="col-md-6 col-sm-12 co-xs-12 form-group">
                                    <input type="email" name="email" placeholder="Correo" />
                                </div>
                                 <div className="col-md-12 col-sm-12 co-xs-12 form-group">
                                    <input type="text" name="phone" placeholder="Telefono" />
                                </div>
                                <div className="col-md-12 col-sm-12 co-xs-12 form-group">
                                    <textarea name="message" placeholder="Mensaje"></textarea>
                                </div>
                            </div>
                            
                            <div className="text-left"><button type="submit" className="theme-btn">ENVIAR</button></div>
                            
                        </form>
                    </div>
                </div>
                
                {/*<!--Info Column-->*/}
                <div className="column info-column col-lg-5 col-md-5 col-sm-6 col-xs-12">
                	<h2><strong>Nuestra</strong> Dirección</h2>
                    <div className="info-box">
                    	<div className="desc-text">UBICACIÓN:
Carretera Federal Colima-Sahuayo México 110, Km. 57, La Llorona, 49500 Mazamitla, Jal.
Fraccionamiento Camino Real de Mazamitla
(10 minutos del centro de Mazamitla)
</div>
                        <ul className="contact-info">
                            <li><div className="icon"><span className="flaticon-technology-2"></span></div><strong>Telefono</strong> 33 2033 1609</li>
                            <li><div className="icon"><span className="flaticon-interface-3"></span></div><strong>Correo</strong> montepantera2@gmail.com</li>
                        </ul>
                        <div className="social-links-two">
                            <a target='_blank' href="https://www.facebook.com/Monte-Pantera-346864636397205/?ref=page_internal"><span className="fa fa-facebook-f"></span></a>
                            <a href="#"><span className="fa fa-twitter"></span></a>
                            <a href="#"><span className="fa fa-google-plus"></span></a>
                            <a href="#"><span className="fa fa-linkedin"></span></a>
                            <a href="#"><span className="fa fa-instagram"></span></a>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
        
        <CalltoAction />

        <Footer />

        </>
    )
}