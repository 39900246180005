import React from "react";

export default function Testimonials(){
    return (
        <>

{/*<!--Testimonials-->*/}
    <section className="testimonials-section" style={{backgroundImage:"url(images/background/image-2.jpg)"}}>
		<div className="auto-container">
            
            <div className="sec-title centered">
                <h2><strong>Testi</strong>monios</h2>
            </div>
            
            {/*<!--Slider-->*/}      
        	<div className="testimonials-slider testimonials-carousel">
            	
                {/*<!--Slide-->*/}
                <article className="slide-item">
                    <div className="slide-text">
                        <p>“Increíble cabaña y lugar para relajarse! Mariet es muy atenta y fácil de contactar”</p>
                    </div>
                    <div className="info-box">
                    	<figure className="image-box"><img src="images/resource/testi-1.jpg" alt="" /></figure>
                    	<h3>Jessica, Guadalajara</h3>
                        <p className="designation">Miembro desde el 2020</p>
                    </div>
                </article>
                
                {/*<!--Slide-->*/}
                <article className="slide-item">
                    <div className="slide-text">
                        <p>“Las cabañitas tienen un estilo arquitectónico muy moderno y de muy buen gusto”</p>
                    </div>
                    <div className="info-box">
                    	<figure className="image-box"><img src="images/resource/testi-2.jpg" alt="" /></figure>
                    	<h3>Erika, Guadalajara</h3>
                        <p className="designation">Miembro desde 2018</p>
                    </div>
                </article>
                
                {/*<!--Slide-->*/}
                <article className="slide-item">
                    <div className="slide-text">
                        <p>“Excelente lugar, altamente recomendable”</p>
                    </div>
                    <div className="info-box">
                    	<figure className="image-box"><img src="images/resource/testi-3.jpg" alt="" /></figure>
                    	<h3>Bernando, Guadalajara</h3>
                        <p className="designation">Miembro desde 2016</p>
                    </div>
                </article>
                
                {/*<!--Slide-->*/}
                <article className="slide-item">
                    <div className="slide-text">
                        <p>“El lugar hermoso y único.”</p>
                    </div>
                    <div className="info-box">
                    	<figure className="image-box"><img src="images/resource/testi-4.jpg" alt="" /></figure>
                    	<h3>Juan Eduardo, El arenal</h3>
                        <p className="designation">Miembro desde 2017</p>
                    </div>
                </article>
                
                {/*<!--Slide-->*/}
                <article className="slide-item">
                    <div className="slide-text">
                        <p>“Mi experiencia fue increíble, la cabaña está hecha un sueño, la atención excelente y el lugar impecable”</p>
                    </div>
                    <div className="info-box">
                    	<figure className="image-box"><img src="images/resource/testi-5.jpg" alt="" /></figure>
                    	<h3>Nancy, Guadalajara</h3>
                        <p className="designation">Miembro desde 2016</p>
                    </div>
                </article>
                
                {/*<!--Slide-->*/}
                <article className="slide-item">
                    <div className="slide-text">
                        <p>“Excelente ubicación 10 min máx de mazamitla, muy tranquilo, cabañas increíblemente cómodas”</p>
                    </div>
                    <div className="info-box">
                    	<figure className="image-box"><img src="images/resource/testi-6.jpg" alt="" /></figure>
                    	<h3>Gerardo, Puerto Vallarta</h3>
                        <p className="designation">Miembro desde 2019</p>
                    </div>
                </article>
                
                {/*<!--Slide-->*/}
                <article className="slide-item">
                    <div className="slide-text">
                        <p>“Perfectas y nuevas instalaciones para pasar tu fin de semana.”</p>
                    </div>
                    <div className="info-box">
                    	<figure className="image-box"><img src="images/resource/testi-7.jpg" alt="" /></figure>
                    	<h3>Obed, Hermosillo</h3>
                        <p className="designation">Miembro desde 2018</p>
                    </div>
                </article>
            
                
                {/*<!--Slide-->*/}
                <article className="slide-item">
                    <div className="slide-text">
                        <p>“Tiene un estilo de decoración inimaginable, vista, todo es muy cómodo y lo mejor es que no hay mosquitos”</p>
                    </div>
                    <div className="info-box">
                    	<figure className="image-box"><img src="images/resource/testi-8.jpg" alt="" /></figure>
                    	<h3>Julio, Guadalajara</h3>
                        <p className="designation">Miembro desde 2019</p>
                    </div>
                </article>
                
                
            </div>
            
        </div>    
    </section>

        </>
    )
}