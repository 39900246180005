import React from "react";

export default function MapSection(){
    return (
        <>

{/*<!--Map Section-->*/}
    <section className="map-section">
        {/*<!--Map Container-->*/}
        <div className="map-outer">
            {/*<!--Map Canvas-->*/}
            <div className="map-canvas"
                data-zoom="12"
                data-lat="19.9192078"
                data-lng="-103.0300888"			  
                data-type="roadmap"
                data-hue="#ffc400"
                data-title="Dhaka"
                data-content="mazamitla, jalisco<br><a href='mailto:info@youremail.com'>info@youremail.com</a>">
            </div>
            
            {/*<!--Info Outer-->*/}
            <div className="outer-container">
            	<div className="info-container wow bounceInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                	<div className="row clearfix">
                    	{/*<!--Info COlumn-->*/}
                        <div className="info-column col-md-4 col-sm-6 col-xs-12">
                        	<div className="inner-box">
                            	<div className="icon"><span className="flaticon-location-2"></span></div>
                                <h4>Mazamitla</h4>
                                <div className="text">Km. 57, La Llorona,49500</div>
                            </div>
                        </div>
                        
                        {/*<!--Info COlumn-->*/}
                        <div className="info-column col-md-4 col-sm-6 col-xs-12">
                        	<div className="inner-box">
                            	<div className="icon"><span className="flaticon-note"></span></div>
                                <h4>Enviar Correo</h4>
                                <div className="text">montepantera2@gmail.com</div>
                            </div>
                        </div>
                        
                        {/*<!--Info COlumn-->*/}
                        <div className="info-column col-md-4 col-sm-6 col-xs-12">
                        	<div className="inner-box">
                            	<div className="icon"><span className="flaticon-technology-2"></span></div>
                                <h4>Llamanos</h4>
                                <div className="text">33 2033 1609</div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </div>
    </section>
    

        </>
    )
}