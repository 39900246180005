import React from "react";

export default function AboutCompany(){
    return (
        <>
        {/*<!--About Company-->*/}
    <section className="about-company">
    	<div className="auto-container">
            
            <div className="row clearfix">
                
                {/*<!--Image Column-->*/}
                <div className="column image-column col-md-4 col-sm-6 col-xs-12">
                	<figure className="image-box wow zoomInStable" data-wow-delay="0ms" data-wow-duration="2000ms"><img src="images/resource/pareja2.jpg" alt="" /></figure>
                </div>
                
                {/*<!--Content Column-->*/}
                <div className="column content-column col-md-8 col-sm-6 col-xs-12">
                	<div className="title-box">
                    	<h5>Introducción</h5>
                        <h3><strong>Acerca de </strong> Monte Pantera</h3>
                    </div>
                    
                    <div className="featured-text">Disfruta de la experiencia, <strong>en pareja ó con tu familia</strong>, contamos con 4 
cabañas diferentes que tienen una capacidad para 2 a 3 personas.</div>
                    <div className="text">Somos un complejo de cabañas de lujo en combinación con funcionalidad y 
comodidad dentro de la naturaleza y los encantos de Mazamitla, Jalisco, 
convirtiéndonos en un lugar ideal para descansar.</div>
                    
                    <ul className="styled-list-three clearfix">
                    	<li><strong>Variedad de Estilos</strong>4 Cabañas a Elegir</li>
                        <li><strong>De Ensueño</strong>Pueblo Mágico</li>
                        <li><strong>Buena Ubicación</strong>a 10min.de Mazamitla</li>
                    </ul>
                    
                    <a href="/galeria" className="theme-btn btn-download"><span className="icon flaticon-download"></span> Galeria de Cabañas</a>
                    
                </div>
                
            </div>
            
        </div>
    </section>
        </>
    )
}