import React from "react";
import AboutCompany from "../../layout/AboutCompany";

export default function CourselPuma(){
    return(
        <>
        
        {/*<!--Tour Section-->*/}
        <section className="tour-section">
            <div className="auto-container">
                
                {/*<!--Carousel Container-->*/}
                <div className="gallery-carousel-container">
                    
                    {/*<!-- Tour Gallery -->*/}
                    <div className="ms-gallery-template" id="tour-gallery">
                        {/*<!-- masterslider -->*/}
                        <div className="master-slider ms-skin-black-2 round-skin" id="masterslider">
                            
                            <div className="ms-slide">
                                <img src="css/masterslider/style/blank.gif" data-src="images/gallery/cabaña-puma-big/1.jpg" alt="lorem ipsum dolor sit"/>
                                <img src="images/gallery/cabaña-puma-small/1.jpg" alt="thumb-1" className="ms-thumb"/>
                                <div className="ms-info">Monte puma</div>
                            </div>
                            
                            <div className="ms-slide">
                                <img src="css/masterslider/style/blank.gif" data-src="images/gallery/cabaña-puma-big/2.jpg" alt="lorem ipsum dolor sit"/>
                                <img src="images/gallery/cabaña-puma-small/2.jpg" alt="thumb-1" className="ms-thumb"/>
                                <div className="ms-info">Monte puma</div>
                            </div>
                            
                            <div className="ms-slide">
                                <img src="css/masterslider/style/blank.gif" data-src="images/gallery/cabaña-puma-big/3.jpg" alt="lorem ipsum dolor sit"/>
                                <img src="images/gallery/cabaña-puma-small/3.jpg" alt="thumb-1" className="ms-thumb"/>
                                <div className="ms-info">Monte puma</div>
                            </div>
                            
                            <div className="ms-slide">
                                <img src="css/masterslider/style/blank.gif" data-src="images/gallery/cabaña-puma-big/4.jpg" alt="lorem ipsum dolor sit"/>
                                <img src="images/gallery/cabaña-puma-small/4.jpg" alt="thumb-1" className="ms-thumb"/>
                                <div className="ms-info">Monte puma</div>
                            </div>
                            
                            <div className="ms-slide">
                                <img src="css/masterslider/style/blank.gif" data-src="images/gallery/cabaña-puma-big/5.jpg" alt="lorem ipsum dolor sit"/>
                                <img src="images/gallery/cabaña-puma-small/5.jpg" alt="thumb-1" className="ms-thumb"/>
                                <div className="ms-info">Monte puma</div>
                            </div>
                            
                           
                            
                            <div className="ms-slide">
                                <img src="css/masterslider/style/blank.gif" data-src="images/gallery/cabaña-puma-big/7.jpg" alt="lorem ipsum dolor sit"/>
                                <img src="images/gallery/cabaña-puma-small/7.jpg" alt="thumb-1" className="ms-thumb"/>
                                <div className="ms-info">Monte puma</div>
                            </div>
                            
                            <div className="ms-slide">
                                <img src="css/masterslider/style/blank.gif" data-src="images/gallery/cabaña-puma-big/8.jpg" alt="lorem ipsum dolor sit"/>
                                <img src="images/gallery/cabaña-puma-small/8.jpg" alt="thumb-1" className="ms-thumb"/>
                                <div className="ms-info">Monte puma</div>
                            </div>
                            
                            <div className="ms-slide">
                                <img src="css/masterslider/style/blank.gif" data-src="images/gallery/cabaña-puma-big/9.jpg" alt="lorem ipsum dolor sit"/>
                                <img src="images/gallery/cabaña-puma-small/9.jpg" alt="thumb-1" className="ms-thumb"/>
                                <div className="ms-info">Monte puma</div>
                            </div>
                            <div className="ms-slide">
                                <img src="css/masterslider/style/blank.gif" data-src="images/gallery/cabaña-puma-big/10.jpg" alt="lorem ipsum dolor sit"/>
                                <img src="images/gallery/cabaña-puma-small/10.jpg" alt="thumb-1" className="ms-thumb"/>
                                <div className="ms-info">Monte puma</div>
                            </div>
                            <div className="ms-slide">
                                <img src="css/masterslider/style/blank.gif" data-src="images/gallery/cabaña-puma-big/11.jpg" alt="lorem ipsum dolor sit"/>
                                <img src="images/gallery/cabaña-puma-small/11.jpg" alt="thumb-1" className="ms-thumb"/>
                                <div className="ms-info">Monte puma</div>
                            </div>
                            <div className="ms-slide">
                                <img src="css/masterslider/style/blank.gif" data-src="images/gallery/cabaña-puma-big/12.jpg" alt="lorem ipsum dolor sit"/>
                                <img src="images/gallery/cabaña-puma-small/12.jpg" alt="thumb-1" className="ms-thumb"/>
                                <div className="ms-info">Monte puma</div>
                            </div>

                            <div className="ms-slide">
                                <img src="css/masterslider/style/blank.gif" data-src="images/gallery/cabaña-puma-big/6.jpg" alt="lorem ipsum dolor sit"/>
                                <img src="images/gallery/cabaña-puma-small/6.jpg" alt="thumb-1" className="ms-thumb"/>
                                <div className="ms-info">Monte puma</div>
                            </div>
                            
                        </div>
                        {/*<!-- end of masterslider -->*/}
                    </div>
                    {/*<!-- end of template -->*/}
            
            </div>

            <AboutCompany />


            </div>
    </section>
        
        </>
    )
}