import React from "react";

export default function AboutProperty(){
    return (
        <>
        {/*<!--About Property-->*/}
    <section className="about-property">
    	<div className="auto-container">
        	{/*<!--Sec Title-->*/}
        	<div className="sec-title centered">
            	<h2><strong>Amenidades en</strong> Monte Pantera</h2>
                <div className="desc-text">Disfruta de la experiencia en pareja ó con tu familia, contamos con 4 
cabañas diferentes que tienen una capacidad para 2 a 3 personas.
</div>
            </div>
            
            {/*<!--Property Info-->*/}
            <div className="property-info">
            	<div className="row clearfix">
                	{/*<!--Info BLock-->*/}
                    <div className="info-block col-lg-2 col-md-3 col-sm-4 col-xs-6">
                        <div className="icon-box"><span className="icon flaticon-calendar-2"></span></div>
                        <div className="text"><div className="title">Visitas Agendadas</div><div className="count"></div></div>
                    </div>
                    {/*<!--Info BLock-->*/}
                    <div className="info-block col-lg-2 col-md-3 col-sm-4 col-xs-6">
                        <div className="icon-box"><span className="icon flaticon-bed"></span></div>
                        <div className="text"><div className="title">Camas King size</div><div className="count"></div></div>
                    </div>
                    {/*<!--Info BLock-->*/}
                    <div className="info-block col-lg-2 col-md-3 col-sm-4 col-xs-6">
                        <div className="icon-box"><span className="icon flaticon-shower-head"></span></div>
                        <div className="text"><div className="title">Baños completos</div><div className="count"></div></div>
                    </div>
                    {/*<!--Info BLock-->*/}
                    <div className="info-block col-lg-2 col-md-3 col-sm-4 col-xs-6">
                        <div className="icon-box"><span className="icon flaticon-buildings-1"></span></div>
                        <div className="text"><div className="title">Lunadas</div><div className="count"></div></div>
                    </div>
                    {/*<!--Info BLock-->*/}
                    <div className="info-block col-lg-2 col-md-3 col-sm-4 col-xs-6">
                        <div className="icon-box"><span className="icon flaticon-sink"></span></div>
                        <div className="text"><div className="title">Cocinas</div><div className="count"></div></div>
                    </div>
                    {/*<!--Info BLock-->*/}
                    <div className="info-block col-lg-2 col-md-3 col-sm-4 col-xs-6">
                        <div className="icon-box"><span className="icon flaticon-sport-car"></span></div>
                        <div className="text"><div className="title">Parking para carros</div><div className="count"></div></div>
                    </div>
                </div>
            </div>
            
        </div>
    </section>
    
        </>
    )
}
    
