import React from "react";

export default function PhotoGallerySplit(){
    return (
        <>

{/*<!--Gallery Section-->*/}
    <section className="gallery-section fullwidth no-padd-bottom">
    	<div className="auto-container">
            
            {/*<!--Sec Title-->*/}
        	<div className="sec-title centered">
            	<h2 className="no-margin-bottom"><strong>Foto</strong> Galería</h2>
            </div>
        </div>
            
        <div className="mixitup-gallery">
            
            {/*<!--Filter-->*/}
            <div className="filters text-center">
                <ul className="filter-tabs filter-btns clearfix">
                    <li className="active filter" data-role="button" data-filter="all">Todo</li>
                    <li className="filter" data-role="button" data-filter=".recamaras">Recámaras</li>
                    <li className="filter" data-role="button" data-filter=".baños">Baños</li>
                    <li className="filter" data-role="button" data-filter=".cocinas">Cocinas</li>
                    <li className="filter" data-role="button" data-filter=".exterior">Exterior</li>
                </ul>
            </div>
                
            
            <div className="filter-list clearfix">
                
                {/*<!--Default Portfolio Item-->*/}
                <div className="default-portfolio-item mix mix_all baños col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="inner-box">
                        <figure className="image-box"><img src="images/gallery/baños/1.jpg" alt="" /></figure>
                        {/*<!--Overlay Box-->*/}
                        <div className="overlay-box">
                            <div className="overlay-inner">
                                <div className="content">
                                    <h3><a href="take-tour.html">BAÑOS</a></h3>
                                    <a href="images/gallery/baños/1.jpg" className="lightbox-image image-link" title="Image Caption Here"><span className="icon flaticon-cross"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                {/*<!--Default Portfolio Item-->*/}
                <div className="default-portfolio-item mix mix_all exterior col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="inner-box">
                        <figure className="image-box"><img src="images/gallery/exterior/2.jpg" alt="" /></figure>
                        {/*<!--Overlay Box-->*/}
                        <div className="overlay-box">
                            <div className="overlay-inner">
                                <div className="content">
                                    <h3><a href="take-tour.html">Monte Pantera</a></h3>
                                    <a href="images/gallery/exterior/2.jpg" className="lightbox-image image-link" title="Image Caption Here"><span className="icon flaticon-cross"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
                {/*<!--Default Portfolio Item-->*/}
                <div className="default-portfolio-item mix mix_all baños col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="inner-box">
                        <figure className="image-box"><img src="images/gallery/baños/4.jpg" alt="" /></figure>
                        {/*<!--Overlay Box-->*/}
                        <div className="overlay-box">
                            <div className="overlay-inner">
                                <div className="content">
                                    <h3><a href="take-tour.html">Monte Pantera</a></h3>
                                    <a href="images/gallery/baños/4.jpg" className="lightbox-image image-link" title="Image Caption Here"><span className="icon flaticon-cross"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!--Default Portfolio Item-->*/}
                <div className="default-portfolio-item mix mix_all exterior col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="inner-box">
                        <figure className="image-box"><img src="images/gallery/exterior/3.jpg" alt="" /></figure>
                        {/*<!--Overlay Box-->*/}
                        <div className="overlay-box">
                            <div className="overlay-inner">
                                <div className="content">
                                    <h3><a href="take-tour.html">Monte Pantera</a></h3>
                                    <a href="images/gallery/exterior/3.jpg" className="lightbox-image image-link" title="Image Caption Here"><span className="icon flaticon-cross"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!--Default Portfolio Item-->*/}
                <div className="default-portfolio-item mix mix_all cocinas col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="inner-box">
                        <figure className="image-box"><img src="images/gallery/cocinas/1.jpg" alt="" /></figure>
                        {/*<!--Overlay Box-->*/}
                        <div className="overlay-box">
                            <div className="overlay-inner">
                                <div className="content">
                                    <h3><a href="take-tour.html">Monte Pantera</a></h3>
                                    <a href="images/gallery/cocinas/1.jpg" className="lightbox-image image-link" title="Image Caption Here"><span className="icon flaticon-cross"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 {/*<!--Default Portfolio Item-->*/}
                 <div className="default-portfolio-item mix mix_all exterior col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="inner-box">
                        <figure className="image-box"><img src="images/gallery/exterior/5.jpg" alt="" /></figure>
                        {/*<!--Overlay Box-->*/}
                        <div className="overlay-box">
                            <div className="overlay-inner">
                                <div className="content">
                                    <h3><a href="take-tour.html">Monte Pantera</a></h3>
                                    <a href="images/gallery/exterior/5.jpg" className="lightbox-image image-link" title="Image Caption Here"><span className="icon flaticon-cross"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!--Default Portfolio Item-->*/}
                <div className="default-portfolio-item mix mix_all recamaras col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="inner-box">
                        <figure className="image-box"><img src="images/gallery/recamaras/5.jpg" alt="" /></figure>
                        {/*<!--Overlay Box-->*/}
                        <div className="overlay-box">
                            <div className="overlay-inner">
                                <div className="content">
                                    <h3><a href="take-tour.html">Monte Pantera</a></h3>
                                    <a href="images/gallery/recamaras/5.jpg" className="lightbox-image image-link" title="Image Caption Here"><span className="icon flaticon-cross"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                {/*<!--Default Portfolio Item-->*/}
                <div className="default-portfolio-item mix mix_all recamaras col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="inner-box">
                        <figure className="image-box"><img src="images/gallery/recamaras/3.jpg" alt="" /></figure>
                        {/*<!--Overlay Box-->*/}
                        <div className="overlay-box">
                            <div className="overlay-inner">
                                <div className="content">
                                    <h3><a href="take-tour.html">Monte Pantera</a></h3>
                                    <a href="images/gallery/recamaras/3.jpg" className="lightbox-image image-link" title="Image Caption Here"><span className="icon flaticon-cross"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                {/*<!--Default Portfolio Item-->*/}
                <div className="default-portfolio-item mix mix_all exterior col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="inner-box">
                        <figure className="image-box"><img src="images/gallery/exterior/1.jpg" alt="" /></figure>
                        {/*<!--Overlay Box-->*/}
                        <div className="overlay-box">
                            <div className="overlay-inner">
                                <div className="content">
                                    <h3><a href="take-tour.html">Monte Pantera</a></h3>
                                    <a href="images/gallery/exterior/1.jpg" className="lightbox-image image-link" title="Image Caption Here"><span className="icon flaticon-cross"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!--Default Portfolio Item-->*/}
                <div className="default-portfolio-item mix mix_all baños col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="inner-box">
                        <figure className="image-box"><img src="images/gallery/baños/2.jpg" alt="" /></figure>
                        {/*<!--Overlay Box-->*/}
                        <div className="overlay-box">
                            <div className="overlay-inner">
                                <div className="content">
                                    <h3><a href="take-tour.html">Monte Pantera</a></h3>
                                    <a href="images/gallery/baños/2.jpg" className="lightbox-image image-link" title="Image Caption Here"><span className="icon flaticon-cross"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!--Default Portfolio Item-->*/}
                <div className="default-portfolio-item mix mix_all baños col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="inner-box">
                        <figure className="image-box"><img src="images/gallery/baños/3.jpg" alt="" /></figure>
                        {/*<!--Overlay Box-->*/}
                        <div className="overlay-box">
                            <div className="overlay-inner">
                                <div className="content">
                                    <h3><a href="take-tour.html">Monte Pantera</a></h3>
                                    <a href="images/gallery/baños/3.jpg" className="lightbox-image image-link" title="Image Caption Here"><span className="icon flaticon-cross"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                
                {/*<!--Default Portfolio Item-->*/}
                <div className="default-portfolio-item mix mix_all cocinas col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="inner-box">
                        <figure className="image-box"><img src="images/gallery/cocinas/5.jpg" alt="" /></figure>
                        {/*<!--Overlay Box-->*/}
                        <div className="overlay-box">
                            <div className="overlay-inner">
                                <div className="content">
                                    <h3><a href="take-tour.html">Monte Pantera</a></h3>
                                    <a href="images/gallery/exterior/5.jpg" className="lightbox-image image-link" title="Image Caption Here"><span className="icon flaticon-cross"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               

                {/*<!--Default Portfolio Item-->*/}
                <div className="default-portfolio-item mix mix_all cocinas col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="inner-box">
                        <figure className="image-box"><img src="images/gallery/cocinas/2.jpg" alt="" /></figure>
                        {/*<!--Overlay Box-->*/}
                        <div className="overlay-box">
                            <div className="overlay-inner">
                                <div className="content">
                                    <h3><a href="take-tour.html">Monte Pantera</a></h3>
                                    <a href="images/gallery/cocinas/2.jpg" className="lightbox-image image-link" title="Image Caption Here"><span className="icon flaticon-cross"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<!--Default Portfolio Item-->*/}
                <div className="default-portfolio-item mix mix_all exterior col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="inner-box">
                        <figure className="image-box"><img src="images/gallery/exterior/6.jpg" alt="" /></figure>
                        {/*<!--Overlay Box-->*/}
                        <div className="overlay-box">
                            <div className="overlay-inner">
                                <div className="content">
                                    <h3><a href="take-tour.html">Monte Pantera</a></h3>
                                    <a href="images/gallery/exterior/6.jpg" className="lightbox-image image-link" title="Image Caption Here"><span className="icon flaticon-cross"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!--Default Portfolio Item-->*/}
                <div className="default-portfolio-item mix mix_all exterior col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="inner-box">
                        <figure className="image-box"><img src="images/gallery/exterior/7.jpg" alt="" /></figure>
                        {/*<!--Overlay Box-->*/}
                        <div className="overlay-box">
                            <div className="overlay-inner">
                                <div className="content">
                                    <h3><a href="take-tour.html">Monte Pantera</a></h3>
                                    <a href="images/gallery/exterior/7.jpg" className="lightbox-image image-link" title="Image Caption Here"><span className="icon flaticon-cross"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!--Default Portfolio Item-->*/}
                <div className="default-portfolio-item mix mix_all exterior col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="inner-box">
                        <figure className="image-box"><img src="images/gallery/exterior/8.jpg" alt="" /></figure>
                        {/*<!--Overlay Box-->*/}
                        <div className="overlay-box">
                            <div className="overlay-inner">
                                <div className="content">
                                    <h3><a href="take-tour.html">Monte Pantera</a></h3>
                                    <a href="images/gallery/exterior/8.jpg" className="lightbox-image image-link" title="Image Caption Here"><span className="icon flaticon-cross"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<!--Default Portfolio Item-->*/}
                <div className="default-portfolio-item mix mix_all cocinas col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="inner-box">
                        <figure className="image-box"><img src="images/gallery/cocinas/3.jpg" alt="" /></figure>
                        {/*<!--Overlay Box-->*/}
                        <div className="overlay-box">
                            <div className="overlay-inner">
                                <div className="content">
                                    <h3><a href="take-tour.html">Monte Pantera</a></h3>
                                    <a href="images/gallery/cocinas/3.jpg" className="lightbox-image image-link" title="Image Caption Here"><span className="icon flaticon-cross"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<!--Default Portfolio Item-->*/}
                <div className="default-portfolio-item mix mix_all recamaras col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="inner-box">
                        <figure className="image-box"><img src="images/gallery/recamaras/7.jpg" alt="" /></figure>
                        {/*<!--Overlay Box-->*/}
                        <div className="overlay-box">
                            <div className="overlay-inner">
                                <div className="content">
                                    <h3><a href="take-tour.html">Monte Pantera</a></h3>
                                    <a href="images/gallery/recamaras/7.jpg" className="lightbox-image image-link" title="Image Caption Here"><span className="icon flaticon-cross"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!--Default Portfolio Item-->*/}
                <div className="default-portfolio-item mix mix_all recamaras col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="inner-box">
                        <figure className="image-box"><img src="images/gallery/recamaras/6.jpg" alt="" /></figure>
                        {/*<!--Overlay Box-->*/}
                        <div className="overlay-box">
                            <div className="overlay-inner">
                                <div className="content">
                                    <h3><a href="take-tour.html">Monte Pantera</a></h3>
                                    <a href="images/gallery/recamaras/6.jpg" className="lightbox-image image-link" title="Image Caption Here"><span className="icon flaticon-cross"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!--Default Portfolio Item-->*/}
                <div className="default-portfolio-item mix mix_all cocinas col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="inner-box">
                        <figure className="image-box"><img src="images/gallery/cocinas/4.jpg" alt="" /></figure>
                        {/*<!--Overlay Box-->*/}
                        <div className="overlay-box">
                            <div className="overlay-inner">
                                <div className="content">
                                    <h3><a href="take-tour.html">Monte Pantera</a></h3>
                                    <a href="images/gallery/cocinas/4.jpg" className="lightbox-image image-link" title="Image Caption Here"><span className="icon flaticon-cross"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
                
            </div>

            
            
        </div>


        
        
    </section>
    

        </>
    )
}