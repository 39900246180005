import React from "react";

export default function MainHeader(){
    return (
        <>
        {/*<!--Main Header-->*/}
            <header className="main-header">
                
                {/*<!--Header-Upper-->*/}
                <div className="header-upper">
                    <div className="auto-container">
                        <div className="clearfix">
                            
                            <div className="pull-left logo-outer">
                                <div className="logo"><a href="/"><img src="images/logo.jpg" alt="" title="MontePantera" /></a></div>
                            </div>
                            
                            <div className="pull-right upper-right clearfix">
                                
                                {/*<!--Info Box-->*/}
                                <div className="upper-column info-box">
                                    <div className="icon-box"><span className="flaticon-technology-2"></span></div>
                                    <ul>
                                        <li><strong>33 2033 1609</strong></li>
                                        <li><a href="#">contacto@montepantera.com</a></li>
                                        <li><a href="#">montepantera2@gmail.com</a></li>
                                    </ul>
                                </div>
                                
                                {/*<!--Info Box-->*/}
                                <div className="upper-column info-box">
                                    <div className="icon-box"><span className="flaticon-location-2"></span></div>
                                    <ul>
                                        <li><strong>Camino Real de Mazamitla</strong></li>
                                        <li>Carretera Federal Colima-Sahuayo México 110, </li>
                                        <li>Km. 57, La Llorona, 49500 Mazamitla, Jal.</li>
                                    </ul>
                                </div>
                                
                                {/*<!--Info Box-->*/}
                                <div className="upper-column info-box">
                                    <div className="social-links-one">
                                        <a target='_blank' href="https://www.facebook.com/Monte-Pantera-346864636397205/?ref=page_internal"><span className="fa fa-facebook-f"></span></a>
                                        <a href="#"><span className="fa fa-twitter"></span></a>
                                        <a href="#"><span className="fa fa-google-plus"></span></a>
                                        <a href="#"><span className="fa fa-linkedin"></span></a>
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
                
                {/*<!--Header-Lower-->*/}
                <div className="header-lower">
                    {/*<!--Background Layer-->*/}
                    <div className="bg-layer"></div>
                    
                    
                    <div className="auto-container">
                        <div className="nav-outer clearfix">
                            {/*<!-- Main Menu -->*/}
                            <nav className="main-menu">
                                <div className="navbar-header">
                                    {/*<!-- Toggle Button -->*/}    	
                                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    </button>
                                </div>
                                
                                <div className="navbar-collapse collapse clearfix">
                                    <ul className="navigation clearfix">
                                        <li><a href="/">Inicio</a></li>
                                        <li><a href="/acercade">Acerca de</a></li>
                                        <li><a href="/galeria">Galería</a></li>
                                        
                                        <li className="dropdown"><a href="#">Cabañas</a>
                                            <ul>
                                                <li><a href="/monte-pantera-jaguar">Jaguar</a></li>
                                                <li><a href="/monte-pantera-pantera">Pantera</a></li>
                                                <li><a href="/monte-pantera-puma">Puma</a></li>
                                                <li><a href="/monte-pantera-ocelote">Ocelote</a></li>
                                            </ul>
                                        </li>
                                        
                                        <li><a href="/contactanos">Contáctanos</a></li>
                                    </ul>
                                </div>
                            </nav>{/*<!-- Main Menu End-->*/}
                        
                            <div className="btn-outer"><a href="/contactanos" className="theme-btn visit-btn">Agenda un Visita</a></div>
                            
                        </div>
                    </div>
                </div>
                
                
                {/*<!--Sticky Header-->*/}
                <div className="sticky-header">
                    <div className="auto-container clearfix">
                        {/*<!--Logo-->*/}
                        <div className="logo pull-left">
                            <a href="/" className="img-responsive"><img style={{width:65, height:50}} src="images/logo.jpg" alt="MontePantera" /></a>
                        </div>
                        
                        {/*<!--Right Col-->*/}
                        <div className="right-col pull-right">
                            {/*<!-- Main Menu -->*/}
                            <nav className="main-menu">
                                <div className="navbar-header">
                                    {/*<!-- Toggle Button -->*/}    	
                                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    </button>
                                </div>
                                
                                <div className="navbar-collapse collapse clearfix">
                                    <ul className="navigation clearfix">
                                        <li><a href="/">Inicio</a></li>
                                        <li><a href="/acercade">Acerca de</a></li>
                                        <li><a href="/galeria">Galería</a></li>
                                        
                                        <li className="dropdown"><a href="#">Cabañas</a>
                                            <ul>
                                                <li><a href="/monte-pantera-jaguar">Jaguar</a></li>
                                                <li><a href="/monte-pantera-pantera">Pantera</a></li>
                                                <li><a href="/monte-pantera-puma">Puma</a></li>
                                                <li><a href="/monte-pantera-ocelote">Ocelote</a></li>
                                            </ul>
                                        </li>
                                        
                                        <li><a href="/contactanos">Contáctanos</a></li>
                                    </ul>
                                </div>
                            </nav>{/*<!-- Main Menu End-->*/}
                        </div>
                        
                    </div>
                </div>{/*<!--End Bounce In Header-->*/}
            
            </header>
            {/*<!--End Main Header -->*/}

        </>
    )
}