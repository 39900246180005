import React from 'react';
import {Route, Routes} from 'react-router-dom';
import AboutUs from '../features/AboutUs/aboutUs';
import Contactanos from '../features/Contactanos/contactanos';
import Gallery from '../features/Gallery/gallery';
import Home from '../features/Home/home';
import SinglePageJaguar from '../features/SinglePage/singlePageJaguar';
import SinglePageOcelote from '../features/SinglePage/singlePageOcelote';
import SinglePagePantera from '../features/SinglePage/singlePagePantera';
import SinglePagePuma from '../features/SinglePage/singlePagePuma';




function App() {


  return (
    <div className="App">
      <Routes>
          <Route path='/' element={<Home />}> </Route>
          <Route path='/acercade' element={<AboutUs />}></Route>
          <Route path='/galeria' element={<Gallery />}></Route>
          <Route path='/contactanos' element={<Contactanos />}></Route>
          <Route path='/monte-pantera-jaguar' element={<SinglePageJaguar />}></Route>
          <Route path='/monte-pantera-pantera' element={<SinglePagePantera />}></Route>
          <Route path='/monte-pantera-puma' element={<SinglePagePuma />}></Route>
          <Route path='/monte-pantera-ocelote' element={<SinglePageOcelote />}></Route>
      </Routes>
      
    </div>
    
  );
}

export default App;
