import React from "react";

export default function Footer(){
    return (

        <>

{/*<!--Main Footer-->*/}
    <footer className="main-footer" style={{backgroundImage:`url(${process.env.PUBLIC_URL + 'images/background/footer-bg.jpg'})`,
  backgroundRepeat: 'no-repeat'}}>
    	
        {/*<!--Footer Upper-->*/}        
        <div className="footer-upper">
            <div className="auto-container">
                <div className="row clearfix">
                	
                    {/*<!--Two 4th column-->*/}
                    <div className="col-md-6 col-sm-12 col-xs-12">
                    	<div className="row clearfix">
                            <div className="col-lg-6 col-sm-6 col-xs-12 column">
                                <div className="footer-widget about-widget">
                                    <div className="logo"><a href="/"><img src="images/logo-two.jpg" className="img-responsive" alt="" /></a></div>
                                    <div className="text">
                                        <p>Carretera Federal Colima-Sahuayo México 110, Km. 57, La Llorona, 49500 Mazamitla, Jal.
Fraccionamiento Camino Real de Mazamitla
</p>
                                    </div>
                                    
                                    <div className="social-links clearfix">
                                    	<a target='_blank' href="https://www.facebook.com/Monte-Pantera-346864636397205/?ref=page_internal" className="facebook img-circle"><span className="fa fa-facebook-f"></span></a>
                                        <a href="#" className="twitter img-circle"><span className="fa fa-twitter"></span></a>
                                        <a href="#" className="google-plus img-circle"><span className="fa fa-google-plus"></span></a>
                                        <a href="#" className="linkedin img-circle"><span className="fa fa-pinterest-p"></span></a>
                                        <a href="#" className="linkedin img-circle"><span className="fa fa-instagram"></span></a>
                                    </div>
                                    
                                </div>
                            </div>
                    		{/*<!--Footer Column-->*/}
                        	<div className="col-lg-6 col-sm-6 col-xs-12 column">
                            	<div className="footer-widget tweets-widget">
                                	<h2>Ultimos Comentarios</h2>	
                                    <div className="tweet">
                                    	<div className="icon"><span className="fa fa-twitter"></span></div>
                                        <div className="content"><a href="#">“Excelente lugar, altamente recomendable”</a></div>
                                        <div className="time">Julio 2, 2021</div>
                                    </div>
                                    
                                    <div className="tweet">
                                    	<div className="icon"><span className="fa fa-twitter"></span></div>
                                        <div className="content"><a href="#">“Perfectas y nuevas instalaciones para pasar tu fin de semana.”</a></div>
                                        <div className="time">Octubre 15, 2021</div>
                                    </div>
                                    
                                </div>
                            </div>
                            
                    	</div>
                    </div>{/*<!--Two 4th column End-->*/}
                    
                    {/*<!--Two 4th column-->*/}
                    <div className="col-md-6 col-sm-12 col-xs-12">
                    	<div className="row clearfix">
                            
                            {/*<!--Footer Column-->*/}
                            <div className="col-lg-5 col-sm-6 col-xs-12 column">
                                <h2>Enlaces rapidos</h2>
                                <div className="footer-widget links-widget">
                                    <ul>
                                        <li><a href="/">Inicio</a></li>
                                        <li><a href="/acercade">Acercade</a></li>
                                        <li><a href="/galeria">Galería</a></li>
                                        <li><a href="/galeria">Cabañas</a></li>
                                        <li><a href="/contactanos">Contáctanos</a></li>
                                        
                                    </ul>
        
                                </div>
                            </div>
                            
                            {/*<!--Footer Column-->*/}
                            <div className="col-lg-7 col-sm-6 col-xs-12 column">
                                <div className="footer-widget news-widget">
                                	<h2>Cabañas mas visitadas</h2>	
                                    
                                    {/*<!--News Post-->*/}
                                    <div className="news-post">
                                        <div className="news-content">
                                        	<figure className="image-thumb"><img src="images/resource/post-thumb-1.jpg" alt="" /></figure>
                                            <h4><a href="/monte-pantera-pantera">Cabaña Pantera</a></h4>
                                        	<div className="text"><a href="#">chimenea, parking, cocina, jacuzzi, internet...</a></div>
                                        </div>
                                        <div className="time">Actualizado 2021</div>
                                    </div>
                                    
                                    {/*<!--News Post-->*/}
                                    <div className="news-post">
                                        <div className="news-content">
                                        	<figure className="image-thumb"><img src="images/resource/post-thumb-2.jpg" alt="" /></figure>
                                            <h4><a href="/monte-pantera-jaguar">Cabaña Jaguar</a></h4>
                                        	<div className="text"><a href="#">lunada (espacio en exterior para hacer fogata..)</a></div>
                                        </div>
                                        <div className="time">Junio, 2021</div>
                                    </div>
                                    
                                </div>
                            </div>
                    	</div>
                    </div>{/*<!--Two 4th column End-->*/}
                    
                </div>
                
            </div>
        </div>
        
        {/*<!--Footer Bottom-->*/}
    	<div className="footer-bottom">
            <div className="auto-container clearfix">
                {/*<!--Copyright-->*/}
                <div className="copyright text-center">Copyright 2021 &copy; Pagina Creada por <a target='_blank' href="https://www.solucionesmoca.com">MOCA</a>  Solutions</div>
            </div>
        </div>
        
    </footer>

        </>
    )
}