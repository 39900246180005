import React from "react";
import AboutCompany from "../../layout/AboutCompany";
import CabañasBlog from "../../layout/CabañasBlog";
import CalltoAction from "../../layout/CallToAction";
import Footer from "../../layout/Footer";
import MainHeader from "../../layout/MainHeader";
import Testimonials from "../../layout/Testimonials";

export default function AboutUs(){
    return (
        <>
        <MainHeader />

        {/*<!--Page Title-->*/}
    <section className="page-title" style={{ 
  backgroundImage: `url(${process.env.PUBLIC_URL + 'images/background/bg-page-title.jpg'})`,
  backgroundRepeat: 'no-repeat'
}}>
    
    {/*<!--style={{backgroundImage:"url(images/background/bg-page-title.jpg);"}}>-->*/}
    
    	<div className="auto-container">
        	<div className="sec-title">
                <h1>Acerca de Monte Pantera</h1>
            </div>
        </div>
    </section>
    
    {/*<!--Breadcrumb-->*/}
    <div className="breadcrumb-outer">
    	<div className="auto-container">
        	<div className="bread-crumb text-center"><a href="/">Home</a> / <a href="#" className="current">Acerca de Monte Pantera</a></div>
        </div>
    </div>
    
    <AboutCompany />


    <Testimonials />

    <CabañasBlog />

    <CalltoAction />

    <Footer />

        </>
    )
}