import React from "react";
import emailjs from 'emailjs-com';

export default function Agent(){

    function sendEmail(e:any){
        e.preventDefault();
        emailjs.sendForm('service_z658fs5', 'template_peqii2i', e.target, 'user_Q9KfTFeZSFprVL9iUItaW').then(res => {
            alert('Mensaje Enviado con Exito');
            console.log(res);
        }).catch(err => {
            alert('Ocurrio un error al enviar');
            console.log(err);
        }
            
        );
    }


    return (
        <>

{/*<!--Agent Section-->*/}
    <section className="agent-section">
    	<div className="auto-container">
        	<div className="row clearfix">
            	{/*<!--Agent Box-->*/}
            	<div className="agent-box col-md-8 col-sm-12 col-xs-12">
                	<div className="row clearfix">
                    	
                        {/*<!--Image Column-->*/}
                        <div className="column col-md-5 col-sm-5 col-xs-12">
                        	<figure className="image-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms"><img src="images/resource/team-image-1.jpg" alt="" /></figure>
                        </div>
                        
                        {/*<!--Content Column-->*/}
                        <div className="column col-md-7 col-sm-7 col-xs-12">
                        	<div className="basic-info">
                            	<h4>Agentes</h4>
                                <div className="designation">Agente de Monte Pantera</div>
                            </div>
                            <div className="desc-text">Nuestros agentes son especialistas residenciales certificados con más de 10 años 
                            de experiencia en ayudar a encontrar la mejor cabaña de acuerdo a tus necesidades. 
                            Puedes mandarle un mensaje por nuestra página o por WhatsApp  y ella te proporcionara informes.</div>
                            <ul className="contact-info">
                            	<li><div className="icon"><span className="flaticon-technology-2"></span></div><strong>Telefono</strong> (33) 2033 1609</li>
                                <li><div className="icon"><span className="flaticon-interface-3"></span></div><strong>Email</strong> contacto@montepantera.com montepantera2@gmail.com</li>
                                
                            </ul>
                            <div className="social-links-two">
                            	<a target='_blank' href="https://www.facebook.com/Monte-Pantera-346864636397205/?ref=page_internal"><span className="fa fa-facebook-f"></span></a>
                                <a href="#"><span className="fa fa-twitter"></span></a>
                                <a href="#"><span className="fa fa-google-plus"></span></a>
                                <a href="#"><span className="fa fa-linkedin"></span></a>
                                <a href="#"><span className="fa fa-instagram"></span></a>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
                {/*<!--Form Column-->*/}
            	<div className="form-column col-md-4 col-sm-12 col-xs-12">
                	<div className="title-box">
                    	<h5>Para consultas</h5>
                        <h3><strong>Contactar </strong>al Agente</h3>
                    </div>
                    {/*<!--Default Form-->*/}
                    <div className="default-form">
                    	<form onSubmit={sendEmail}>
                        	<div className="form-group">
                            	<input type="text" name="fullname" placeholder="Nombre " required />
                            </div>
                            <div className="form-group">
                            	<input type="email" name="email" placeholder="Correo" required />
                            </div>
                            <div className="form-group">
                            	<textarea name="message" placeholder="Mensaje" required></textarea>
                            </div>
                            <div className="text-left"><button type="submit" className="theme-btn">ENVIAR</button></div>
                            
                        </form>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    

        </>
    )
}