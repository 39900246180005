import React from "react";

export default function CabañasBlog(){
    return (
        <>

{/*<!--Blog News Section-->*/}
    <section className="blog-news-section">
    	<div className="auto-container">
        	
            <div className="sec-title text-center">
                <h2><strong>Cabañas</strong> Monte Pantera</h2>
                <div className="desc-text">El mejor lugar para disfrutar la comodidad y la naturaleza.</div>
            </div>
            
        	<div className="row clearfix">
                
                {/*<!--News Column-->*/}
                <div className="column blog-news-column col-lg-4 col-md-4 col-sm-6 col-xs-12">
                	<article className="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                		<figure className="image-box">
                        	<a href="/monte-pantera-jaguar"><img src="images/resource/jaguar.jpg" alt="" /></a>
                            <a href="#" className="category">Cabañas</a>
                        </figure>
                        <div className="content-box">
                        	<h3><a href="/monte-pantera-jaguar">Cabaña Jaguar</a></h3>
                            <ul className="post-info clearfix">
                                <li>Monte<a href="#">Pantera</a></li>
                                <li>En<a href="#">Mazamitla, Jalisco.</a></li>
                            </ul>
                            <div className="text">Parking para 1 carro, cocina, baño completo, jacuzzi, cama king size, terraza con asador, tv e internet, chimenea y lunada.</div>
                            <a href="/monte-pantera-jaguar" className="theme-btn read-more">VER MAS</a>
                        </div>
                    </article>
                </div>

                {/*<!--News Column-->*/}
                <div className="column blog-news-column col-lg-4 col-md-4 col-sm-6 col-xs-12">
                	<article className="inner-box wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                		<figure className="image-box">
                        	<a href="/monte-pantera-pantera"><img src="images/resource/pantera.jpg" alt="" /></a>
                            <a href="#" className="category">Cabañas</a>
                        </figure>
                        <div className="content-box">
                        	<h3><a href="/monte-pantera-pantera">Cabaña Pantera</a></h3>
                            <ul className="post-info clearfix">
                                <li>Monte<a href="#">Pantera</a></li>
                                <li>En<a href="#">Mazamitla, Jalisco.</a></li>
                            </ul>
                            <div className="text">Parking para 1 carro, cocina, baño completo, jacuzzi, cama king size, terraza con asador, tv e internet, chimenea, lunada.</div>
                            <a href="/monte-pantera-pantera" className="theme-btn read-more">VER MAS</a>
                        </div>
                    </article>
                </div>

                
                {/*<!--News Column-->*/}
                <div className="column blog-news-column col-lg-4 col-md-4 col-sm-6 col-xs-12">
                	<article className="inner-box wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                		<figure className="image-box">
                        	<a href="/monte-pantera-puma"><img src="images/resource/puma.jpg" alt="" /></a>
                            <a href="#" className="category">Cabañas</a>
                        </figure>
                        <div className="content-box">
                        	<h3><a href="/monte-pantera-puma">Cabaña Puma</a></h3>
                            <ul className="post-info clearfix">
                                <li>Monte<a href="#">Pantera</a></li>
                                <li>En<a href="#">Mazamitla, Jalisco.</a></li>
                            </ul>
                            <div className="text">Parking para 1 carro,  cocina, baño  completo, cama king  size, terraza con asador, tv e internet, chimenea, lunada..</div>
                            <a href="/monte-pantera-puma" className="theme-btn read-more">VER MAS</a>
                        </div>
                    </article>
                </div>

                 {/*<!--News Column-->*/}
                 <div className="column blog-news-column col-lg-4 col-md-4 col-sm-6 col-xs-12">
                	<article className="inner-box wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                		<figure className="image-box">
                        	<a href="/monte-pantera-ocelote"><img src="images/resource/ocelote.jpg" alt="" /></a>
                            <a href="#" className="category">Cabañas</a>
                        </figure>
                        <div className="content-box">
                        	<h3><a href="/monte-pantera-ocelote">Cabaña Ocelote</a></h3>
                            <ul className="post-info clearfix">
                                <li>Monte<a href="#">Pantera</a></li>
                                <li>En<a href="#">Mazamitla, Jalisco.</a></li>
                            </ul>
                            <div className="text">Parking para 1 carro,  cocina, baño  completo, cama king  size, sofá cama (individual), terraza, internet, chimenea y lunada.</div>
                            <a href="/monte-pantera-ocelote" className="theme-btn read-more">VER MAS</a>
                        </div>
                    </article>
                </div>

                {/*<!--News Column-->*/}
                <div className="column blog-news-column col-lg-4 col-md-4 col-sm-6 col-xs-12">
                	<article className="inner-box wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                		<figure className="image-box">
                        	<a href="/galeria">

                            <video width="390" height="239" controls >
                                <source src="videos/IMG_7711.mp4" type="video/mp4"/>
                            </video>
                            </a>
                            <a href="#" className="category">Cabañas</a>
                        </figure>
                        <div className="content-box">
                        	<h3><a href="/galeria">Monte Pantera</a></h3>
                            <ul className="post-info clearfix">
                                <li>Monte<a href="#">Pantera</a></li>
                                <li> En<a href="#"> Mazamitla, Jalisco.</a></li>
                            </ul>
                            <div className="text">Somos un complejo de cabañas de lujo en combinación con funcionalidad y 
comodidad dentro de la naturaleza y los encantos de Mazamitla....
</div>
                            <a href="/galeria" className="theme-btn read-more">VER MAS</a>
                        </div>
                    </article>
                </div>


                
                
                
                
                
                
            </div>
        </div>
    </section>

        </>
    )
}