import React from "react";
import CalltoAction from "../../layout/CallToAction";
import Footer from "../../layout/Footer";
import MainHeader from "../../layout/MainHeader";
import CourselPantera from "./CourselPantera";

export default function SinglePagePantera(){
    return (
        <>
        <MainHeader />


        {/*<!--Page Title-->*/}
        <section className="page-title" style={{ 
        backgroundImage: `url(${process.env.PUBLIC_URL + 'images/background/bg-page-title.jpg'})`,
        backgroundRepeat: 'no-repeat'
        }}>

        <div className="auto-container">
        <div className="sec-title">
                <h1>CABAÑA PANTERA</h1>
            </div>
        </div>
        </section>

        {/*<!--Breadcrumb-->*/}
        <div className="breadcrumb-outer">
        <div className="auto-container">
            <div className="bread-crumb text-center"><a href="/">Inicio</a> / <a href="#" className="current">CABAÑA PANTERA</a></div>
        </div>
        </div>

        <CourselPantera />

        <CalltoAction />

        <Footer />
        </>
    )
}