import React from "react";

export default function MainSlider(){
    return (
        <>

         
    {/*<!--Main Slider-->*/}
    <section className="main-slider">
    	
        <div className="tp-banner-container">
            <div className="tp-banner">
                <ul>
                	
                    
                    <li data-transition="fade" data-slotamount="1" data-masterspeed="1000" data-thumb="images/main-slider/1.jaguar.jpg"  data-saveperformance="off"  data-title="Cabaña Jaguar">
                    <img src="images/main-slider/1.jaguar.jpg"  alt=""  data-bgposition="center top" data-bgfit="cover" data-bgrepeat="no-repeat" /> 
                    
                    
                        <div className="tp-caption sfl sfb tp-resizeme"
                        data-x="right" data-hoffset="15"
                        data-y="center" data-voffset="20"
                        data-speed="1500"
                        data-start="0"
                        data-easing="easeOutExpo"
                        data-splitin="none"
                        data-splitout="none"
                        data-elementdelay="0.01"
                        data-endelementdelay="0.3"
                        data-endspeed="1200"
                        data-endeasing="Power4.easeIn">
                        
                            {/*<!--Slide Content-->*/}
                            <div className="outer-box">
                                <div className="upper-content">
                                    <h2><strong>Cabañas</strong></h2>
                                    <div className="text">El mejor lugar para disfrutar la  <br />comodidad y la naturaleza.</div>
                                    <ul className="info-list clearfix">
                                        <li><strong>1</strong> Terraza</li>
                                        <li><strong>1</strong> Jacuzzi</li>
                                        <li><strong>1</strong> Cocina</li>
                                    </ul>
                                </div>
                                <div className="lower-info">
                                    <div className="data-cell price">JAGUAR</div>
                                    <a href="/monte-pantera-jaguar" className="data-cell read-more">VER DETALLES</a>
                                </div>
                            </div>
                            
                        </div>
                    
                    </li>
                    
                    <li data-transition="slidedown" data-slotamount="1" data-masterspeed="1000" data-thumb="images/main-slider/2.ocelote.jpg"  data-saveperformance="off"  data-title="Cabaña Ocelote">
                    <img src="images/main-slider/2.ocelote.jpg"  alt=""  data-bgposition="center top" data-bgfit="cover" data-bgrepeat="no-repeat" /> 
                    
                    
                        <div className="tp-caption sfb sfb tp-resizeme"
                        data-x="left" data-hoffset="0"
                        data-y="center" data-voffset="20"
                        data-speed="1500"
                        data-start="0"
                        data-easing="easeOutExpo"
                        data-splitin="none"
                        data-splitout="none"
                        data-elementdelay="0.01"
                        data-endelementdelay="0.3"
                        data-endspeed="1200"
                        data-endeasing="Power4.easeIn">
                        
                            {/*<!--Slide Content-->*/}
                            <div className="outer-box">
                                <div className="upper-content text-center">
                                    <h2><strong>Cabañas</strong></h2>
                                    
                                    <div className="text">Carretera Colima-Sahuayo <br />La Llorona, 49500 Mazamitla, Jal.</div>
                                    <ul className="info-list clearfix">
                                        <li><strong>1</strong> Chimenea</li>
                                        <li><strong>1</strong> Lunada</li>
                                        <li><strong>1</strong> Cama King size</li>
                                    </ul>
                                </div>
                                <div className="lower-info">
                                    <div className="data-cell price">OCELOTE</div>
                                    <a href="/monte-pantera-ocelote" className="data-cell read-more">VER DETALLES</a>
                                </div>
                            </div>
                            
                        </div>
                    
                    </li>
                    
                    <li data-transition="fade" data-slotamount="1" data-masterspeed="1000" data-thumb="images/main-slider/3.puma.jpg"  data-saveperformance="off"  data-title="Cabaña Puma">
                    <img src="images/main-slider/3.puma.jpg"  alt=""  data-bgposition="center top" data-bgfit="cover" data-bgrepeat="no-repeat" /> 
                    
                    
                        <div className="tp-caption sfr sfb tp-resizeme"
                        data-x="right" data-hoffset="-15"
                        data-y="center" data-voffset="20"
                        data-speed="1500"
                        data-start="0"
                        data-easing="easeOutExpo"
                        data-splitin="none"
                        data-splitout="none"
                        data-elementdelay="0.01"
                        data-endelementdelay="0.3"
                        data-endspeed="1200"
                        data-endeasing="Power4.easeIn">
                        
                            {/*<!--Slide Content-->*/}
                            <div className="outer-box">
                                <div className="upper-content">
                                    <h2><strong>Cabañas</strong></h2>
                                    <div className="text">El mejor lugar para disfrutar la  <br />comodidad y la naturaleza.</div>
                                    <ul className="info-list clearfix">
                                        <li><strong>2</strong> Tv e Internet</li>
                                        <li><strong>1</strong> Chimenea</li>
                                        <li><strong>1</strong> Terraza con asador</li>
                                    </ul>
                                </div>
                                <div className="lower-info">
                                    <div className="data-cell price">PUMA</div>
                                    <a href="/monte-pantera-puma" className="data-cell read-more">VER DETALLES</a>
                                </div>
                            </div>
                            
                        </div>
                    
                    </li>
                    
                    <li data-transition="slideup" data-slotamount="1" data-masterspeed="1000" data-thumb="images/main-slider/4.pantera.jpg"  data-saveperformance="off"  data-title="Cabaña Pantera">
                    <img src="images/main-slider/4.pantera.jpg"  alt=""  data-bgposition="center top" data-bgfit="cover" data-bgrepeat="no-repeat" /> 
                    
                    
                        <div className="tp-caption sft sfb tp-resizeme"
                        data-x="left" data-hoffset="0"
                        data-y="center" data-voffset="20"
                        data-speed="1500"
                        data-start="0"
                        data-easing="easeOutExpo"
                        data-splitin="none"
                        data-splitout="none"
                        data-elementdelay="0.01"
                        data-endelementdelay="0.3"
                        data-endspeed="1200"
                        data-endeasing="Power4.easeIn">
                        
                            {/*<!--Slide Content-->*/}
                            <div className="outer-box">
                                <div className="upper-content text-center">
                                    <h2><strong>Cabañas</strong> </h2>
                                    <div className="text">Carretera Colima-Sahuayo <br />La Llorona, 49500 Mazamitla, Jal.</div>
                                    <ul className="info-list clearfix">
                                        <li><strong>1</strong> Jacuzzi</li>
                                        <li><strong>1</strong> Chimenea</li>
                                        <li><strong>1</strong> Lunada</li>
                                    </ul>
                                </div>
                                <div className="lower-info">
                                    <div className="data-cell price">PANTERA</div>
                                    <a href="/monte-pantera-pantera" className="data-cell read-more">VER DETALLES</a>
                                </div>
                            </div>
                            
                        </div>
                    
                    </li>
                    
                </ul>
                
            </div>
        </div>
    </section>

        </>
    )
}