import React from "react";

export default function CalltoAction(){
    return (

        <>

{/*<!--Call To Action-->*/}
    <section className="call-to-action">
    	<div className="auto-container">
        	<div className="row clearfix">
            	{/*<!--Column-->*/}
                <div className="column col-md-9 col-sm-12 col-xs-12">
                	<h2>Contáctanos</h2>
                    Escríbenos y reserva el mejor lugar para disfrutar la comodidad y la naturaleza.
                </div>
                {/*<!--Column-->*/}
                <div className="column col-md-3 col-sm-12 col-xs-12">
                	<div className="text-right padd-top-20">
                		<a href="/contactanos" className="theme-btn btn-style-two">Reserva ahora!</a>
                    </div>
                </div>
            </div>
        </div>
    </section>

        </>
    )
}