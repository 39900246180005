import React from "react";
import AboutCompany from "../../layout/AboutCompany";
import AboutProperty from "../../layout/AboutProperty";
import Agent from "../../layout/Agent";
import CabañasBlog from "../../layout/CabañasBlog";
import Footer from "../../layout/Footer";
import MainHeader from "../../layout/MainHeader";
import MainSlider from "../../layout/MainSlider";
import MapSection from "../../layout/MapSection";
import PhotoGallerySplit from "../../layout/PhotoGallerySplit";
import Testimonials from "../../layout/Testimonials";

export default function Home(){

    return(
        <>
            <MainHeader />
            <MainSlider />
            <AboutCompany />
            <CabañasBlog />
            <PhotoGallerySplit />
            <AboutProperty />
            <Testimonials />
            <Agent />
            <MapSection />
            <Footer />
        </>
    )
}