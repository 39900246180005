import React from "react";
import CalltoAction from "../../layout/CallToAction";
import Footer from "../../layout/Footer";
import MainHeader from "../../layout/MainHeader";
import PhotoGallerySplit from "../../layout/PhotoGallerySplit";

export default function Gallery(){
    return (
        <>
            <MainHeader />


             {/*<!--Page Title-->*/}
            <section className="page-title" style={{ 
                backgroundImage: `url(${process.env.PUBLIC_URL + 'images/background/bg-page-title.jpg'})`,
                backgroundRepeat: 'no-repeat'
            }}>
            
            <div className="auto-container">
                <div className="sec-title">
                        <h1>Galería</h1>
                    </div>
                </div>
            </section>
            
            {/*<!--Breadcrumb-->*/}
            <div className="breadcrumb-outer">
                <div className="auto-container">
                    <div className="bread-crumb text-center"><a href="/">Inicio</a> / <a href="#" className="current">Galería</a></div>
                </div>
            </div>

            <PhotoGallerySplit />

            <div style={{textAlign:'center', margin:20}}>
                <video width="780" height="439" controls >
                    <source src="videos/IMG_7711.mp4" type="video/mp4"/>
                </video>

            </div>
            
            <CalltoAction />

           

            <Footer />
        </>
    )
}